// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getAuth} from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "essay-scoring-6b5dc.firebaseapp.com",
  projectId: "essay-scoring-6b5dc",
  storageBucket: "essay-scoring-6b5dc.appspot.com",
  messagingSenderId: "667333200345",
  appId: "1:667333200345:web:8a18ad3733c317c7d271c8"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);