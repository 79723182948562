import React, { useState } from 'react';
import {  Paper, Tab, Tabs, Button, Grid, Typography, Stack, CircularProgress } from '@mui/material';


function DataTabs({ tabData , isLoading}) {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Paper elevation={0} sx={{ p: 2, height: '100%' }}>
      <Tabs value={selectedTab} onChange={handleChange}>
        <Tab label="Overall" />
        <Tab label="Task Achievement" />
        <Tab label="Lexical Resource" />
        <Tab label="Grammatical Range" />
        <Tab label="Coherence and Cohesion" />
      </Tabs>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <div>
          {tabData.map((data, index) => (
            <Typography
              component="div"
              role="tabpanel"
              hidden={selectedTab !== index}
              key={index}
            >
              {data}
            </Typography>
          ))}
        </div>
      )}
  </Paper>
  );
}

export default DataTabs;
