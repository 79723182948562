import React from 'react';
import Body from './Body';
import Footer from './Footer';
import { Box, CssBaseline, Paper } from '@mui/material';

const Layout = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '90vh', // Make the container take the full viewport height
      }}
    >
      <CssBaseline />
      <Paper
        sx={{
          flex: '1', // Allow the Body to grow and fill available space
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Body />
      </Paper>
      <Footer />
    </Box>
  );
};

export default Layout;
