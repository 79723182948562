import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    mode: "light",
  },
  typography: {
    fontFamily: [
      'Arial',
      'sans-serif',
    ].join(','),
  },
  
});


export default theme;
