import React, { useEffect } from 'react';
import Layout from './Layout'
import Navbar from './Navbar'
import { UserAuth } from '../Auth/AuthContext';
import { useNavigate } from 'react-router-dom';


export const LandingPage = () => {

    const { user } = UserAuth();
    const navigate = useNavigate();


    useEffect(() => {
        if (Object.keys(user).length !== 0) {
            navigate('/app');
        }
    }, [user]);

    return (
    <>
        <Navbar loggedIn={false}></Navbar>
        <Layout></Layout>
    </>
    )
}
