import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';


function createDivsFromObject(obj) {
    let htmlArray = [];

    for (const [task, details] of Object.entries(obj)) {
        let taskHtml= <div></div>
        if (task == "overall"){
            taskHtml = 
                        <Box sx={{ padding: 2, margin: 1, backgroundColor: 'background.paper' }}>
                        <Typography variant="h6" component="h6" sx={{ mb: 1 }}>
                        Overall Score: {details.score}
                        </Typography>
                    </Box>;
        }
        else {
            taskHtml = 
                        <Box sx={{ padding: 2, margin: 1, backgroundColor: 'background.paper' }}>
                        <Typography variant="h6" component="h6" sx={{ mb: 1 }}>
                        Score: {details.score}
                        </Typography>
                        <Typography variant="h6" component="h6" sx={{ mb: 1 }}>
                        Task Requirements:
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 1 }}>
                        {details.task_requirements}
                        </Typography>
                    
                        <Typography variant="h6" component="h6" sx={{ mb: 1 }}>
                        Reasoning for Score:
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 1 }}>
                        {details.reasoning}
                        </Typography>
                        

                        <Typography variant="h6" component="h6" sx={{ mb: 1 }}>
                        Cosntructive Feedback:
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 1 }}>
                        {details.constructive_feedback}
                        </Typography>
                        
                    </Box>;
        }
        console.log(task, task.details);

        
        htmlArray.push(taskHtml);
    }

    return htmlArray;
}


export default createDivsFromObject;