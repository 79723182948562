// import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Navbar from './components/Navbar';
import Layout from './components/Layout';
import EssayScore from './components/EssayScore';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; // Updated import statements
import { LandingPage } from './components/LandingPage';
import { AuthContextProvider } from './Auth/AuthContext';
import Protected from './Auth/Protected';
import React, { useEffect } from 'react';


function App() {

  useEffect(() => {
    document.title = "Relios";  // Set your custom page title here
  }, []);


  return (
    <AuthContextProvider>
      <Router>
      <Routes> {/* Use Routes instead of Switch */}
        <Route path="/" element={<LandingPage/>} /> 
        <Route
          path='/app'
          element={
            <Protected>
              <EssayScore />
            </Protected>
          }
        />
      </Routes>
    </Router>
    </AuthContextProvider>
    
  );
}

export default App;
