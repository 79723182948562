import React from 'react';
import { Box, Container, Grid, Typography , Button} from '@mui/material';
import DemoVideo from './DemoVideo'; // Import the DemoVideo component
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'; // This icon acts as a custom bullet point
import { UserAuth } from '../Auth/AuthContext';

const Body = () => {

  const { googleSignIn, logOut, user } = UserAuth();

    const handleGoogleSignIn = async () => {
        try {
            await googleSignIn();
        } catch (error) {
            console.log(error);
        }
        };

  return (
    <Container sx={{ mt: 4 }}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <Typography variant="h4" gutterBottom>
              Instant IELTS Essay Scoring!
            </Typography>
            
            <Typography variant="body1" paragraph>
            Welcome to Relios – your ultimate companion on the journey to mastering the IELTS writing section. With our innovative AI-driven platform, Relios specializes in providing precise and in-depth grading for IELTS essays, mirroring the exacting standards of the actual exam. Our goal is to empower IELTS candidates to achieve their desired scores by offering clear, actionable insights into their writing.

            <List component="nav" aria-label="IELTS feedback points">
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="small" /> {/* Small dot icon as bullet point */}
                  </ListItemIcon>
                  <ListItemText 
                    primary={
                      <Typography component="span" variant="body1">
                        <strong>Tailored IELTS Grading:</strong> Our AI algorithms are specifically calibrated to the IELTS writing assessment criteria, ensuring your essays are evaluated just like they would be in the actual test.
                      </Typography>
                    } 
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText 
                    primary={
                      <Typography component="span" variant="body1">
                        <strong>Detailed Score Breakdown:</strong> Beyond just an overall score, Relios provides a granular breakdown across key criteria - Task Achievement, Coherence and Cohesion, Lexical Resource, and Grammatical Range and Accuracy. This approach helps pinpoint exactly where you excel and where you need to focus your efforts.
                      </Typography>
                    } 
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText 
                    primary={
                      <Typography component="span" variant="body1">
                        <strong>Realistic Practice:</strong> Submit your essays on a variety of IELTS topics, receiving feedback that mirrors the real testing conditions and standards, helping you to become comfortable and confident with the exam format.
                      </Typography>
                    } 
                  />
                </ListItem>
          </List>
            <Button variant="contained" color="primary" sx={{ mt: 2 }} onClick={handleGoogleSignIn}>
            Get Started for Free
            </Button>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <DemoVideo /> {/* Include the DemoVideo component here */}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Body;
