import React from 'react';
import { Container, Typography, Link, Grid } from '@mui/material';

const Footer = () => {
  return (
    <footer sx={{ mt: 4, backgroundColor: '#333', color: 'white' }}>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="h6">Company Information</Typography>
            <Typography variant="body2">
              Address: 248, Meadowsweet Trail, 
              London, ON, CA
            </Typography>
            <Typography variant="body2">
              Phone: +1 (929) 319-9557
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h6">Social Media</Typography>
            <Link href="https://www.instagram.com/reliosapp/" color="inherit" variant="body2">
              Facebook
            </Link>
            <br />
            <Link href="https://www.instagram.com/reliosapp/" color="inherit" variant="body2">
              Instagram
            </Link>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
};

export default Footer;
