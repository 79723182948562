import React from 'react';
import ReactPlayer from 'react-player';

const DemoVideo = () => {
  return (
      <ReactPlayer
        url="https://youtu.be/B6ofvulhmpw" // Replace with your video URL
        width="100%"
        height="100%"
        controls // Show video controls (play, pause, volume, etc.)
      />
  );
};

export default DemoVideo;
