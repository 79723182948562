import React from 'react';
import { AppBar, Box, Toolbar, Typography, Button, Container } from '@mui/material';
import { UserAuth } from '../Auth/AuthContext';


const Navbar = ({loggedIn}) => {

    const { googleSignIn, logOut, user } = UserAuth();

    const handleGoogleSignIn = async () => {
        try {
            await googleSignIn();
        } catch (error) {
            console.log(error);
        }
        };

    const handleSignOut = async () => {
    try {
        await logOut();
    } catch (error) {
        console.log(error);
    }
    };

    return (
    <AppBar position="sticky">
        <Toolbar>
            <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
            <img src="/images/logo.png" alt="Company Logo" style={{ width: '70px',height:'70px', marginRight: '10px' }} />
            <Typography variant="h6">
                Relios 
            </Typography>
            </Box>
            
            {
            loggedIn?
            <Button color="inherit" style={{ marginLeft: 'auto' }} onClick={handleSignOut}>
            Logout
            </Button>
            : 
            <Button color="inherit" style={{ marginLeft: 'auto' }} onClick={handleGoogleSignIn}>
            Login with Google
            </Button> 
            }
        </Toolbar>
    </AppBar>
    );
    };

export default Navbar;