import React, { useState } from 'react';
import { Paper, Button, Grid, TextField, Stack, Box } from '@mui/material';
import Navbar from './Navbar';
import DataTabs from './WritingDataTabs';
import axios from 'axios';
import { UserAuth } from '../Auth/AuthContext';

import createDivsFromObject from './renderUtils';

const EssayScore = () => {
  const [promptText, setPromptText] = useState('');
  const [inputText, setInputText] = useState('');
  const [tabData, setTabData] = useState([<div />, <div />, <div />, <div />, <div />]);
  const [isLoading, setLoading] = useState(false);

  const { user } = UserAuth();

  const handlePromptChange = (event) => {
    setPromptText(event.target.value);
  };

  const handleInputChange = (event) => {
    setInputText(event.target.value); 
  };

  // New function to handle generating prompts
  const handleGeneratePrompt = async () => {
    try {
      setLoading(true);
      const promptUrl = `${process.env.REACT_APP_API_URL_BACKEND_ROOT}get_random_prompt`; // Adjust if necessary
      const response = await axios.get(promptUrl);
      const randomPrompt = response.data.random_prompt;
      setPromptText(randomPrompt); // Update the promptText state with the random prompt
    } catch (error) {
      console.error('Error fetching random prompt:', error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const url = process.env.REACT_APP_API_URL_BACKEND; // Replace with your actual API URL
      const data = {
          prompt: promptText,
          essay: inputText,
          user_uid: user.uid,
      }
      const response = await axios.post(url, data, {
        timeout: 600000, // Adjust timeout as needed
        headers: {
            'Content-Type': 'application/json',
        }
      });
      const responseData = response.data;
      const transformedData = createDivsFromObject(responseData);
      setTabData(transformedData);
    } catch (error) {
        console.error('Error making request:', error.message);
        // Handle error response here if needed
        throw error;
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Navbar loggedIn={true}></Navbar>
      <Grid container spacing={0} sx={{ height: '100%' }}>
        <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column', height: '100%' }} >
          <Paper elevation={0} sx={{ p: 2, flex: 1, height: '100%' }}>
            <TextField fullWidth multiline rows={5} label="Prompt.." value={promptText} sx={{ marginBottom: '10px' }} onChange={handlePromptChange}/>
            <TextField fullWidth multiline rows={28} label="Write your essay here.." value={inputText} sx={{ marginBottom: '10px' }} onChange={handleInputChange} />
            <Stack direction="row" spacing={{ xs: 1, sm: 2, md: 4 }}>
              <Button variant="contained" color="inherit" onClick={handleGeneratePrompt}>Generate Prompt [Optional]</Button>
              <Box flexGrow={1}></Box>
              <Button variant="contained" color="inherit" onClick={handleSubmit}>Submit</Button>
            </Stack>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <DataTabs tabData={tabData} isLoading={isLoading}/>
        </Grid>
      </Grid>
    </>
  );
};

export default EssayScore;
